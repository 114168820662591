<template>
<div class="home">
  <div class="row">
    <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
      <span style="color:#777; font-size:18px;padding:5px;">Students</span>/<span style="padding:5px; color:#555">Email update</span>
    </div>
  </div>

  <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid"></div>

  <div class=" mb-8">
    <v-card v-if="showform" style="margin: 0 auto"  width="700">
      <v-card-title>
        Find Student
      </v-card-title>
      <v-divider style="margin:0; padding:0"></v-divider>
      <div class="pa-12">
        <v-text-field dense outlined label="Enter Reg Number" v-model="regNumber"></v-text-field>

        <v-btn color="#c0e7fe" block :disabled="loading" :loading="loading" @click="fetchStudent" class="ma-auto pa-3">Next</v-btn>
        <!-- <v-btn color="primary" class="pa-3" block @click="shownext=true">Next</v-btn> -->
      </div>
    </v-card>

    <v-card v-else>
      <v-row>
        <div class="col-md-4">
          <div>
            <button style="padding: .9rem; background: #ccc" @click="back">
              <!-- <img src="../../../assets/arrowleft.svg" alt="" srcset="" /> -->
            </button>
          </div>
        </div>
        <div class="col-md-4"></div>
        
      </v-row>
      <v-card>
        <div class="row">
          <div class="col-md-3">

          </div>

          <div class="col-md-6">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12">
                  <img v-if="item.profileUrl" class="avatar" :src="item.profileUrl" alt="">
                  <img v-else class="avatar" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt="">

                </v-col>
              </v-row>
              <v-row>
                <div class="col-md-8 pr-8">
                  <v-text-field dense outlined label="Regnumber" v-model="item.regNumber" readonly></v-text-field>
                  <v-text-field dense outlined label="Surname" v-model="item.surname" readonly></v-text-field>
                  <v-text-field dense outlined label="Name" v-model="item.firstname" readonly></v-text-field>
                  <v-text-field dense outlined label="Department" v-model="item.department.name" readonly></v-text-field>
                  <v-text-field dense outlined label="Level" v-model="item.level" readonly></v-text-field>
                  <v-alert type="info">
                    Email should be students official UNIZIK email address. Also provide the student default email password. The student will see this default password on his/her app profile page.
                  </v-alert>
                  <v-text-field  dense outlined label="Email" v-model="item.email"></v-text-field>
                  <v-text-field  dense outlined label="Default Email Password" v-model="password"></v-text-field>

                  <v-btn color="#c0e7fe" block :disabled="!item.email" :loading="loading1" @click="editemail" class="ma-auto pa-3" >Save</v-btn>
                </div>
              </v-row>
            </v-card-text>
          </div>

        </div>

      </v-card>
    </v-card>

  </div>
</div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
export default {
  data() {
    return {
      item: {},
      showform: true,
      regNumber: '',
      loading1: false,
      loading: false,
      item:{email: ''},
      password:'',
    }
  },
 
  methods: {
    back(){
      this.showform = true;
      this.email = '';
      this.regNumber = '';
      this.loading = false;
      this.item = {};
      this.password=''
    },
   async editemail() {
     try {
      var url = this.$appUrl+'/update-student-email'
      const res  = await axios.post(url, {regNumber: this.regNumber, email: this.item.email, password:this.password});

      if(res.data.code == 's200'){
        Swal.fire({
            icon: 'success',
            text: 'Email successfully edited',
            title: 'Succcessful'
          });

          this.showform = true;
          this.back();
      }else{
        Swal.fire({
            icon: 'error',
            text: res.data.message,
            title: 'Error'
          });
      }
     } catch (error) {
      console.log(error);
     }
    },



    async fetchStudent() {
      if(this.regNumber == ''){
        Swal.fire({
            icon: 'error',
            text: 'Provide student registration number',
            title: 'Find Student'
          });
      }
      this.loading = true;
      try {
        var url = this.$appUrl+'/get-student';
        const res = await axios.post(url, {
          regNumber: this.regNumber
        });

        console.log(res);

        if (res.data.code == 's200') {
          this.item = res.data.message;
          this.showform = false;
        } else {
          Swal.fire({
            icon: 'error',
            text: res.data.message,
            title: 'Error'
          });
        }

        this.loading = false
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    }
  }
}
</script>

<style scoped>
.avatar {
  height: 120px;
  width: 120px
}
</style>
