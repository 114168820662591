<template>
<div class="home">
  <div class="row">
    <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
      <span style="color:#777; font-size:18px;padding:5px;">Students</span>/<span style="padding:5px; color:#555">Credit Limit Extension</span>
    </div>
  </div>

  <div  class=" container-fluid row m-auto" style="background: #f5f5f5;margin-top:150px !important">
    <div style="margin:auto; padding:20px; " class="col-md-12">

    <v-card v-if="showform" style="margin: 0 auto"  width="700">
      <v-card-title>
        Find Student
      </v-card-title>
      <v-divider style="margin:0; padding:0"></v-divider>
      <div class="pa-12">
        <v-text-field dense outlined label="Enter Reg Number" v-model="regNumber"></v-text-field>

        <v-btn color="#c0e7fe" block :disabled="loading" :loading="loading" @click="fetchStudent" class="ma-auto pa-3">Find student</v-btn>
        <!-- <v-btn color="primary" class="pa-3" block @click="shownext=true">Next</v-btn> -->
      </div>
    </v-card>

    <v-row style="margin:0 !important; padding:0 !important" v-else>
        <v-col cols="12" style="margin:40px 0 0 0 !important; padding:0 !important">
          <div class="col-md-4">
          <div>
            <button style="padding: .9rem; background: #ccc" @click="back">
              <v-icon>mdi-keyboard-backspace</v-icon>
              <!-- <img src="../../../assets/arrowleft.svg" alt="" srcset="" /> -->
            </button>
          </div>
        </div>
    <v-card >
      <v-row>
        <!-- <div class="col-md-4"></div> -->
        
      </v-row>
        <div class="row">
          <div class="col-md-6">
            <v-card-title>
             <h6>Student information</h6> 
            </v-card-title>
            <v-divider class="m-0 p-0"></v-divider>
            <div class="col-md-10 m-auto">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12">
                  <img v-if="item.profileUrl" class="avatar" :src="item.profileUrl" alt="">
                  <img v-else class="avatar" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt="">

                </v-col>
              </v-row>
              <v-row>
                <div class="col-md-10">
                  <v-text-field dense outlined label="Regnumber" v-model="item.regNumber" readonly></v-text-field>
                  <v-text-field dense outlined label="Surname" v-model="item.surname" readonly></v-text-field>
                  <v-text-field dense outlined label="Name" v-model="item.firstname" readonly></v-text-field>
                  <v-text-field dense outlined label="Department" v-model="item.department.name" readonly></v-text-field>
                  <v-text-field dense outlined label="Level" v-model="item.level" readonly></v-text-field>
                  <!-- <v-alert type="info">
                    Email should be students official UNIZIK email address. Also provide the student default email password. The student will see this default password on his/her app profile page.
                  </v-alert>
                  <v-text-field  dense outlined label="Email" v-model="item.email"></v-text-field>
                  <v-text-field  dense outlined label="Default Email Password" v-model="password"></v-text-field>

                  <v-btn color="#c0e7fe" block :disabled="!item.email" :loading="loading1" @click="editemail" class="ma-auto pa-3" >Save</v-btn> -->
                </div>
                
              </v-row>
            </v-card-text>
            </div>
          </div>

          <div class="col-md-6" style="border-left: solid 1px #ccc;">
            <v-card-title>
              <h6>Extension information</h6>
            </v-card-title>
            <v-divider class="m-0 p-0"></v-divider>

            <div class="col-md-10 m-auto"> 
           
            <div class="mt-5">
              <!-- <v-autocomplete v-model="semester" :items="semesters" style="max-width: 100% !important" outlined label="Semester" dense></v-autocomplete> -->
<v-alert type="info">
  Student already has credit limit extension set for this session. To edit this the admin with access to view credit extension report will have to deactivate this extension before you can edit.
</v-alert>
            <v-autocomplete v-model="depSearch" :items="departmentSearch" style="max-width: 100% !important" outlined label="Department extending to" dense v-if="!foundExtension"></v-autocomplete>
            <v-text-field dense outlined label="Extended Department" v-model="depSearch" type="text" :readonly="foundExtension" v-else></v-text-field>
            <v-text-field dense outlined label="No of extra units" v-model="unit" type="number" :readonly="foundExtension"></v-text-field>
            <v-btn color="#c0e7fe" block  :loading="loading1" @click="addCredit" class="ma-auto pa-3"  :disabled="foundExtension">Extend Credit Limit</v-btn>
            </div>
            </div>
</div>
        </div>

    </v-card>
</v-col>
</v-row>
</div>
</div>
</div>
</template>

<script>
import Swal from 'sweetalert2';
const axiosCall = require("../helpers/axiosInstance.js")
import {allDepartments} from "@Appmart/utility";

export default {
  data() {
    return {
      item: {},
      semesters:[
        1,2
      ],
      semester:'',
      showform: true,
      departmentSearch:[],
      unit:'',
      depSearch:'',
      regNumber: '',
      loading1: false,
      loading: false,
      foundExtension:false,
      allDepartments:''

    }
  },
 mounted(){

  this.mydepartments();
    this.allowedMenu = this.$router.currentRoute.params.getmyMenus;
 },


  methods: {

    async mydepartments() {
        try {

                    const alldepartments = await allDepartments()
          .then((departments) => {
            return departments;
          })
          .catch((e) => {
            console.log(e);
          });

console.log('chiiii',this.allowedMenu);

        ///// fiter the departments that are needed.
this.allDepartments = alldepartments.data.message
        alldepartments.data.message.forEach(dep => {
        // this.allowedMenu.department.some((f) => {
          // if(f == dep._id){
        this.departmentSearch.push({
                            text: dep.name,
                            value: {
                                id: dep._id,
                                name: dep.name,
                                code: dep.code
                            }
                        });
                      // }
                    })
      //  });



      }catch(e){
console.log(e);
      }

    },



    back(){
      this.showform = true;
      this.email = '';
      this.regNumber = '';
      this.loading = false;
      this.item = {};
      this.password='',
      this.foundExtension=false
    },
   async addCredit() {

    if(this.depSearch == ''){
        Swal.fire({
            icon: 'error',
            text: 'Extending department is required',
            title: 'Find Student'
          });
      }

      if(this.unit == ''){
        Swal.fire({
            icon: 'error',
            text: 'Extending unit is required',
            title: 'Find Student'
          });
      }

      if(this.unit <= 0){
        Swal.fire({
            icon: 'error',
            text: 'Extending unit needs to be a positive number',
            title: 'Find Student'
          });
      }
    this.loading = true;

     try {
      var url = this.$appUrl+'/addlimitextension'
const data = {item:this.item,unit:this.unit,depSearch:this.depSearch}

      const res  = await  axiosCall.axiosPost(url, {data});

      if(res.data.code == 's200'){
        Swal.fire({
            icon: 'success',
            text: 'Crefit limit successfully extended',
            title: 'Credit limit extension'
          });

          this.showform = true;
          this.back();
      }else{
        Swal.fire({
            icon: 'error',
            text: res.data.message,
            title: 'Credit limit extension'
          });
      }
     } catch (error) {
      console.log(error);
     }
    },



    async fetchStudent() {
      this.foundExtension = false
      if(this.regNumber == ''){
        Swal.fire({
            icon: 'error',
            text: 'Provide student registration number',
            title: 'Find Student'
          });
      }
      this.loading = true;

      try {
        var url = this.$appUrl+'/getlimitextensions';
        var data ={
          regNumber: this.regNumber
        }
        const res = await axiosCall.axiosPost(url,{data} );

        console.log(res);

        if (res.data.code == 's200') {
          this.item = res.data.message.studentData;

        const allowed = this.allowedMenu.department.includes(this.item.department._id)
        if(allowed){
          if(res.data.message.checkLimitExt){
            this.foundExtension = true
            this.unit= res.data.message.checkLimitExt.requestedLimit
            let findDpt = this.allDepartments.filter(x=>{
              console.log({x});
              return x._id==res.data.message.checkLimitExt.finalDeptId
            })
            console.log(findDpt[0].name);
              this.depSearch = findDpt[0].name
              
          }
          this.showform = false;
        }else{
          Swal.fire({
            icon: 'error',
            text: 'You are not permited to extend credit for this student as student is not in a department you moderate',
            title: 'Credit Extension'
          }); 
        }
        } else {
          Swal.fire({
            icon: 'error',
            text: res.data.message,
            title: 'Error'
          });
        }

        this.loading = false
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    }




  }
}
</script>

<style scoped>
.avatar {
  height: 120px;
  width: 120px
}
</style>
