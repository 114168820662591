<template>
<div>
  fddfdfdfdfdfdfdfdfdfdfdfdf
    <v-row>
        <div style="margin:auto; padding:20px" class="col-md-9">
            <v-card elevation="3">
                <v-card-title class="text-h7" style="color:#666">
                    Biometric Capture Result
                </v-card-title>
                <v-divider style="margin-top:0px"></v-divider>

                <div v-if="foundPic">

                    <div class="ma-5">
                        <v-alert type="warning" class="text-h5"> Facial Biometric found an existing capture</v-alert>
                        <!-- {{success}} -->
                        <v-col class="col-md-3">
                            <img :src="success.DataURL" width="150px">
                            <!-- <v-text-field  outlined
             label="Student Identity"  v-model="studentIdentity"></v-text-field>
            <v-btn color="primary" @click="find_admited_student" elevation='2' class="col-md-12" :disabled="studentIdentity==''">Find</v-btn> -->
                        </v-col>
                        <v-col class="col-md-9">
                                STUDENT INFORMATION
                                <v-divider style="margin-top:0px"></v-divider>
                                <div class="row">
                                    <v-col cols="12" sm="4">
                                        <v-text-field v-model="studentInfo.message.surname" readonly label="Surname"></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="4">
                                        <v-text-field v-model="studentInfo.message.name" readonly label="Name"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field v-model="studentInfo.message.othername" readonly label="Name"></v-text-field>
                                    </v-col>
                                </div>
                                <div class="row">
                                    <!-- <v-col cols="12" sm="4">
                                        <v-text-field v-model="studentInfo.message.gender" readonly label="Gender"></v-text-field>
                                    </v-col> -->

                                    <!-- <v-col cols="12" sm="4">
                                        <v-text-field v-model="studentInfo.message.dob" readonly label="Date of Birth"></v-text-field>
                                    </v-col> -->
                                    <v-col cols="12" sm="4">
                                        <v-text-field v-model="studentInfo.message.regNumber" readonly label="Registration No"></v-text-field>
                                    </v-col>
                                </div>
                                <!-- {{studentInfo}} -->
                                <!-- <v-text-field  outlined
             label="Student Identity"  v-model="studentIdentity"></v-text-field>
            <v-btn color="primary" @click="find_admited_student" elevation='2' class="col-md-12" :disabled="studentIdentity==''">Find</v-btn> -->
            <v-col cols="12" sm="12">
                                <v-btn color="success" elevation="5" @click="verifyStudent(studentInfo.message.regnumber)">Use this capture</v-btn><br>
            </v-col>
             <v-col cols="12" sm="12">
                                <v-btn color="error" elevation="5" >Cancel Enrolment</v-btn><br>
            </v-col>
                            </v-col>
                    </div>
                </div>
                <div v-if="enrolledPic">

                    <div class="ma-5" style="margin-bottom:10px !important">
                        <v-alert type="success" class="text-h5"> Facial Biometric Captured</v-alert>
                        <div class="row">
                            <v-col class="col-md-3">
                                <img :src="success.DataURL" width="200px">
                                <!-- <v-text-field  outlined
             label="Student Identity"  v-model="studentIdentity"></v-text-field>
            <v-btn color="primary" @click="find_admited_student" elevation='2' class="col-md-12" :disabled="studentIdentity==''">Find</v-btn> -->
                            </v-col>
                            <v-col class="col-md-9">
                                STUDENT INFORMATION
                                <v-divider style="margin-top:0px"></v-divider>
                                <div class="row">
                                    <v-col cols="12" sm="4">
                                        <v-text-field v-model="studentInfo.message.surname" readonly label="Surname"></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="4">
                                        <v-text-field v-model="studentInfo.message.name" readonly label="Name"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field v-model="studentInfo.message.othername" readonly label="Name"></v-text-field>
                                    </v-col>
                                </div>
                                <div class="row">
                                    <!-- <v-col cols="12" sm="4">
                                        <v-text-field v-model="studentInfo.message.gender" readonly label="Gender"></v-text-field>
                                    </v-col> -->

                                    <!-- <v-col cols="12" sm="4">
                                        <v-text-field v-model="studentInfo.message.dob" readonly label="Date of Birth"></v-text-field>
                                    </v-col> -->
                                    <v-col cols="12" sm="4">
                                        <v-text-field v-model="studentInfo.message.regnumber" readonly label="Registration No"></v-text-field>
                                    </v-col>
                                </div>
                                <!-- {{studentInfo}} -->
                                <!-- <v-text-field  outlined
             label="Student Identity"  v-model="studentIdentity"></v-text-field>
            <v-btn color="primary" @click="find_admited_student" elevation='2' class="col-md-12" :disabled="studentIdentity==''">Find</v-btn> -->
                                <v-btn color="success" elevation="5" @click="verifyStudent(studentInfo.message.regnumber)">Enroll Applicant</v-btn><br>

                            </v-col>
                        </div>
                    </div>
                </div>
            </v-card>
        </div>
    </v-row>
</div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2';

export default {
    name: "BiometricError",

    data() {
        return {
            msg: "",
            success: this.$route.query,
            foundPic: false,
            enrolledPic: false,
            studentInfo: ''

        };
    },

    mounted() {
        this.foundPic = false, this.enrolledPic = false
       // this.success = this.$route.query;
        // if the image exits
        if (this.success.status == '201') {
            this.foundPic = true
        }
        if (this.success.status == '200') {
            this.enrolledPic = true
        }
        // this.msg = sucess;
        this.find_admited_student(this.success.UserID)
    },
    methods: {
        async find_admited_student(x) {
            this.showBiometric = false
            const url = this.$appUrl + '/find_student'
            const findAdmited = await axios({
                    method: 'POST',
                    url: url,
                    data: {
                        entryData: x
                    }
                })
                .then(response => {
                    const responses = response.data
                    if (responses.code == '00') {
                        if (responses.message.status == 3) {
                            this.studentInfo = responses;
                            console.log('ssss');
                        }
                        // if (responses.message.status > 3) {
                        //     this.studentInfo = responses;
                        //     console.log('ssss');
                        // } else {
                        //     Swal.fire({
                        //         icon: 'error',
                        //         // title: 'Empty Form',
                        //         text: responses.message.surname + ' ' + responses.message.name + ' is not done with e-clearance form',
                        //     })
                        // }
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Biometrics',
                            text: 'Admitted Student Not Found',
                        })
                    }
                    if (response.data.code != '00') {
                        console.log(response.data);
                    }

                })

                .catch(e => {
                    console.log(e);
                    alert('Something went wrong')
                })
        },
        async verifyStudent(x) {
            const url = this.$appUrl + '/update_student_bio'
            const findAdmited = await axios({
                method: 'POST',
                url: url,
                data: {
                    entryData: x,
                    biometric_img_link:this.success.DataURL,
                }
            }).then(response => {
                const responses = response.data
                if (responses.code == '00') {
                    Swal.fire({
                        icon: "success",
                        title: 'Biometrics',
                        text: 'Applicant Verified',
                    })
setTimeout(() => {
                      window.location.href = `${window.location.origin}/students/biometrics`
}, 1000);

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Biometrics',
                        text: 'Could not Verify Applicant',
                    })
                }
            })

        }
    }
};
</script>
