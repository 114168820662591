<template>
  <div class="home">
    <div class="row">
      <div
        class="col-md-12"
        style="
          margin-top: 70px !important;
          height: 70px !important;
          padding: 30px 0 0px 10px;
          position: fixed;
          z-index: 1;
          background: #fff;
          border-bottom: solid 1px #ccc;
          padding-left: 40px;
        "
      >
        <span style="color: #777; font-size: 18px; padding: 5px">Settings</span
        >/<span style="padding: 5px; color: #555">Students List</span>
      </div>
    </div>

    <div style="background: #f5f5f5; margin-top: 180px" class="container-fluid">
      <v-card v-if="showform" style="margin: 0 auto" class="pa-12" width="700">
        <div>
          <v-text-field
            dense
            outlined
            label="Enter Reg Number"
            v-model="filter.regNumber"
          ></v-text-field>
          <v-text-field
            dense
            outlined
            label="Enter Surname"
            v-model="filter.surname"
          ></v-text-field>
          <v-autocomplete
            dense
            :items="degrees"
            v-model="filter.degree"
            outlined
            label="Select Degree"
          ></v-autocomplete>
          <v-autocomplete
            dense
            :items="faculties"
            v-model="filter.faculty"
            outlined
            label="Select Faculty" @change="populateRightDepartments()"
          ></v-autocomplete>
          <v-autocomplete
            dense
            :items="departments"
            v-model="filter.department"
            outlined
            label="Select Department"
          ></v-autocomplete>

          <v-autocomplete
            dense
            :loading="loading1"
            :items="levels"
            v-model="filter.level"
            outlined
            label="Select level"
          ></v-autocomplete>

          <v-btn
            color="#c0e7fe"
            block
            :disabled="loading"
            :loading="loading"
            @click="fetchStudents"
            class="ma-auto pa-3"
            >Next</v-btn
          >
          <!-- <v-btn color="primary" class="pa-3" block @click="shownext=true">Next</v-btn> -->
        </div>
      </v-card>

      <v-card v-else>
        <v-row>
          <div class="col-md-4">
            <div>
              <button style="padding: 0.9rem; background: #ccc" @click="back">
                <!-- <img src="../../../assets/arrowleft.svg" alt="" srcset="" /> -->
              </button>
            </div>
          </div>
          <div class="col-md-4"></div>
          <div class="col-md-4 mb-8 pr-8">
            <v-text-field
              v-model="search"
              clearable
              append-icon="mdi-magnify"
              label="Search Student"
              single-line
              hide-details
            ></v-text-field>
          </div>
        </v-row>
        <v-spacer></v-spacer>
                        
        <button @click="csvExport(csvData3)" class="" type="submit" style="color:#999"> <span style="margin-right: 4px"></span> <v-icon color="#999" large>mdi-download-circle</v-icon><span style="font-size:12px">Download</span></button>

        <v-data-table
          :headers="headers"
          item-key="id"
          :search="search"
          :items="desserts"
        >
          <template v-slot:item.actions="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="" text v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="viewStudent(item)">
                  <v-list-item-title
                    ><v-icon small class="mr-2"> mdi-eye </v-icon>View
                    Student</v-list-item-title
                  >
                </v-list-item>
                <v-list-item @click="showEdit(item)">
                  <v-list-item-title
                    ><v-icon small class="mr-2"> mdi-pencil </v-icon>Edit
                    Student Info</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>

      <v-dialog v-model="dialogEdit" width="600">
        <v-card>
          <v-card-title style="color: #666; font-size: 18px">
            Edit Student Info
            <v-spacer></v-spacer>
            <v-icon @click="dialogEdit = false">mdi-close-circle</v-icon>
          </v-card-title>
          <v-divider style="margin-top: 0px"></v-divider>

          <v-card-text class="pa-8">
            <v-text-field
              disabled
              dense
              outlined
              label="Edit Surname"
              v-model="editInfo.regNumber"
            ></v-text-field>
            <v-text-field
              disabled
              dense
              outlined
              label="Edit First Name"
              v-model="editInfo.firstname"
            ></v-text-field>
            <v-text-field
              disabled
              dense
              outlined
              label="Edit Surname"
              v-model="editInfo.surname"
            ></v-text-field>
            <v-text-field
              disabled
              dense
              outlined
              label="Edit Other Names"
              v-model="editInfo.othernames"
            ></v-text-field>
            <v-text-field
              disabled
              dense
              outlined
              label="Edit Email"
              v-model="editInfo.email"
            ></v-text-field>
            <v-text-field
              type="number"
              dense
              outlined
              label="Edit Phone"
              v-model="editInfo.phone"
            ></v-text-field>
            <v-btn
              color="#c0e7fe"
              :disabled="loading"
              :loading="loading"
              @click="resetPassword"
              class="ma-auto pa-3"
              style="width: 140px"
              >Reset Default Password</v-btn>
              <v-spacer></v-spacer>
              <v-btn
              color="#c0e7fe"
              :disabled="loading"
              :loading="loading"
              @click="handleEdit"
              class="ma-auto pa-3"
              style="width: 140px"
              >Edit</v-btn
            >
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogRegset" width="600">
        <v-card>
          <v-card-title style="color: #666; font-size: 18px">
            Student Details
            <v-spacer></v-spacer>
            <v-icon @click="dialogRegset = false">mdi-close-circle</v-icon>
          </v-card-title>
          <v-divider style="margin-top: 0px"></v-divider>

          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12">
                <img
                  v-if="item.profileUrl"
                  class="avatar"
                  :src="item.profileUrl"
                  alt=""
                />
                <img
                  v-else
                  class="avatar"
                  src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                  alt=""
                />
              </v-col>
            </v-row>
            <v-row>
              <div class="col-md-6 px-8">
                <p>
                  <strong
                    >Student Name : {{ item.name }} {{ item.surname }}</strong
                  >
                </p>

                <p>
                  <strong>Phone : {{ item.phone }}</strong>
                </p>
                <p>
                  <strong>Email : {{ item.email }}</strong>
                </p>
                <p>
                  <strong>Gender : {{ item.gender }}</strong>
                </p>
                <p>
                  <strong>Date of birth : {{ item.dob }}</strong>
                </p>
                <p>
                  <strong>State : {{ item.state }}</strong>
                </p>
                <p>
                  <strong>LGA : {{ item.lGA }}</strong>
                </p>
              </div>

              <div class="col-md-6 px-8">
                <p>
                  <strong>Regnumber : {{ item.schoolregno }}</strong>
                </p>
                <p>
                  <strong>Admission Number : {{ item.entryregno }}</strong>
                </p>
                <p>
                  <strong>Program : {{ item.program }}</strong>
                </p>

                <p>
                  <strong>Faculty : {{ item.faculty }}</strong>
                </p>
                <p>
                  <strong>Department : {{ item.department }}</strong>
                </p>

                <p>
                  <strong>Level : {{ item.level }}</strong>
                </p>
                <p>
                  <strong>Entry Mode : {{ item.entryMode }}</strong>
                </p>
              </div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import { degree, allSession, allDepartments, allFaculties,menusAsigned,fetchactiveSets } from "@Appmart/utility";

export default {
  data: () => ({
    programs: [],
    showform: true,
    dialogRegset: false,
    loading1: false,
    faculties: [],
    loading: false,
    departments: [],
    department: "",
    level: "",
    program: "",
    dialogEdit: false,
    degrees: [],
    editInfo: {
      phone: "",
      name: "",
    },
    filter: {
      faculty: "",
      surname: "",
      department: "",
      level: "",
      program: "",
      degree: "",
      regNumber: "",
    },
    levels: [],
    search: "",

    headers: [
      {
        text: "Entry Reg No",
        align: "start",
        sortable: false,
        value: "entryregno",
      },
      {
        text: "School Reg No",
        value: "schoolregno",
      },
      {
        text: "Surname",
        value: "surname",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Other Names",
        value: "othernames",
      },
      // {
      //     text: 'LGA',
      //     value: 'lga'
      // },
      {
        text: "DOB",
        value: "dob",
      },
      // {
      //     text: 'State',
      //     value: 'state'
      // },

      {
        text: "Program",
        value: "programs",
      },
      {
        text: "Faculty",
        value: "faculty",
      },
      {
        text: "Department",
        value: "department",
      },
      {
        text: "Current Level",
        value: "level",
      },
      {
        text: "Year of Entry",
        value: "yearofentry",
      },
      {
        text: "action",
        value: "actions",
      },
    ],
    item: {},

    desserts: [],
    departmentsHolder: [],
  }),
 computed:{
    
            csvData3() {
                return this.desserts.map(item => ({
                   EntryNumber:item.department,
                    RegNumber:item.faculty,
                    level:item.level,
                    ...item,
                }));
            },
        },

  watch: {
    "filter.faculty"(val) {
      console.log(val.id);

      if (val.id) {
        this.populateRightDepartments(val.id);
      }
    },

    "filter.department"(val) {
      if (val) {
        this.loading1 = true;
        this.fetchDepartnetNumberOfYears(val);
      }
    },
  },
  created() {
    //this.filter.program = "61ef4514a88631b45cd21f31";
    this.fetchProgram();
    this.fetchallDegrees();
    this.fetchDepartments();
    this.fetchFaculties();
  },

  methods: {
    async handleEdit() {
      try {
        this.loading = true;
        if (!this.editInfo.phone && this.editInfo.phone == 11) {
          this.loading = false;
          Swal.fire({
            icon: "error",
            text: "Please add phone number to continue",
            title: "Incomplete Form",
          });
        } else {
          var url = this.$appUrl+ "/edit-student"
          const res = await axios.post(
            url,
            this.editInfo
          );

          if (res.data.code == "s200") {
            this.item.phone = this.editInfo.phone;

            Swal.fire({
              icon: "success",
              title: "Successful",
              text: "Student info successfully edited",
            });
          } else {
            Swal.fire({
              icon: "error",
              text: res.data.message,
              title: "Error",
            });
          }

          this.loading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    showEdit(item) {
      this.dialogEdit = true;
      this.item = item;

      this.editInfo.regNumber = item.schoolregno;
      this.editInfo.firstname = item.name;
      this.editInfo.surname = item.surname;
      this.editInfo.othernames = item.othernames;
      this.editInfo.email = item.email;

      this.editInfo.phone = item.phone;
    },
    openOtpSingle() {
      this.showOtp = true;
      this.dialogRegset = false;
    },

    viewStudent(item) {
      this.item = item;
      this.dialogRegset = true;
    },

    back() {
      this.filter = {
        faculty: "",
        surname: "",
        department: "",
        level: "",

        program: "",
        degree: "",
        regNumber: "",
      };
      this.showform = true;
    },

    async fetchFaculties() {
      try {
        const allFacs = await allFaculties()
.then((faculties) => {
return faculties;
})
.catch((e) => {
console.log(e);
});

  this.faculties = allFacs.data.message.map((el) => {
              return {
                text: el.name,
                value: {
                  name: el.name,
                  id: el._id
                }
              }
            });
      } catch (error) {
        console.log(error);
      }
    },
    populateRightDepartments(val) {
      if (val === "") return;
      const departmentDatas = this.departmentsHolder.filter(
        (el) => el.faculty._id === val
      );
      const departments = departmentDatas.map((el) => {
        return {
          text: el.name,
          value: el._id,
        };
      });
      this.departments = departments;
    },

    async fetchDepartnetNumberOfYears(departmentId) {
      try {
        var url = this.$appUrl+ "/fetch-no-year"
        const res = await axios.post(
          url,
          {
            departmentId: departmentId,
            degreeId: this.filter.degree.id,
            //programId: "61ef4514a88631b45cd21f31",
          }
        );
        console.log(res);

        if (res.data.code == "s200") {
          for (let i = 0; i < res.data.message; i++) {
            this.levels.push({
              text: `${i + 1}00`,
              value: i + 1,
            });
          }
        }

        this.loading1 = false;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchallDegrees() {
      try {

        const programDegree = await degree()
          .then((activeSet) => {
            return activeSet;
          })
          .catch((e) => {
            console.log(e);
          });

        // if (degree.data.code == "00") {
          programDegree.data.message.forEach(el => {
                this.degrees.push({
                  text: el.name,
                  value: {
                    id: el._id,
                    name: el.name
                  }
                })
              });
      } catch (error) {
        console.log(error);
      }
    },

    async fetchDepartments() {
      try {

        const alldepartments = await allDepartments()
.then((departments) => {
return departments;
})
.catch((e) => {
console.log(e);
});
        this.departmentsHolder = alldepartments.data.message;

      } catch (error) {
        console.log(error);
      }
    },

    csvExport(arrData) {
                // if (this.filteredItemsthree.length == 0) {
                //     Swal.fire({
                //         icon: "info",
                //         text: "Table Is Empty",
                //         width: 400,
                //     });
                // } else {
                    let csvContent = "data:text/csv;charset=utf-8,";
                    csvContent += [
                            Object.keys(arrData[0]),
                            ...arrData.map(item => Object.values(item))
                        ]
                        // .trim()
                        .join("\n")
                        .replace(/(^\[)|(\]$)/gm, "");
    
                    const data = encodeURI(csvContent);
                    const link = document.createElement("a");
                    link.setAttribute("href", data);
                    link.setAttribute("download", "studentInfo.csv");
                    link.click();
               // }
            },

    async fetchProgram() {
      try {
        const res = await axios.get(
          process.env.VUE_APP_BaseUrl + "/fetchprograms"
        );
        console.log("RESET", res);
        if (res.data.code == "00") {
          res.data.data.forEach((program) => {
            this.programs.push({
              text: program.program,
              value: program._id,
            });
          });
        } else {
          console.log("ERROR");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchStudents() {
      this.loading = true;
      this.filter.faculty =this.filter.faculty.id
      this.filter.degree = this.filter.degree.id
      /*

        faculty:'',
        surname: '',
      department: '',
      level: '',
      program: '',
      degree: '',
      regNumber: ''*/
      try {
        if (
          !this.filter.department &&
          !this.filter.faculty &&
          !this.filter.surname &&
          !this.filter.level &&
          !this.filter.regNumber &&
          !this.filter.degree
        ) {
          this.loading = false;
          Swal.fire({
            icon: "error",
            text: "You can not submit empty form",
            title: "Empty Form",
          });
        } else {
          //filter data
          const newFilter = JSON.parse(JSON.stringify(this.filter));
          // console.log('NEW FILTER', newFilter);
          Object.keys(newFilter).map((key) => {
            if (newFilter[key] === "") {
              delete newFilter[key];
            }
          });

          // const data = {department: this.department, program: this.program, level: this.level};
          var url = this.$appUrl+"/fetch-students"
          const res = await axios.post(
            url,
            newFilter
          );

          if (res.data.code === "s200") {
            console.log("RESPON", res);
            this.desserts = [];
            res.data.message.forEach((student) => {
              this.desserts.push({
                entryregno: student?.admissionNumber,
                schoolregno: student?.regNumber,
                surname: student?.surname,
                name: student?.firstname,
                othernames: student?.othernames,
                // lga: student?.l,
                gender: student.gender,
                phone: student.phone,
                email: student.email,
                dob: `${student?.DOB}-${student?.MOB}-${student?.YOB}`,
                // state: 'Awka State',
                state: student.state,
                id: student._id,
                profileUrl: student.profileUrl,
                entryMode: student.entryMode,
                lga: student.lGA,
                faculty: student?.faculty?.name,
                programs: student?.program?.program,
                department: student?.department?.name,
                level: student?.level + "00",
                yearofentry: student?.yearOfAdmission,
              });
            });

            this.showform = false;
          } else {
            Swal.fire({
              icon: "error",
              text: res.data.message,
              title: "Error",
            });
          }

          this.loading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.avatar {
  border: 2px solid black !important;
  display: block;
  height: 120px;
  width: 120px;
  border-radius: 50%;
}
</style>
