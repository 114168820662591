<template>
  <div class="home">
    dfdfdfdfdfdfdfd
      <div style="margin:auto; padding:20px" class="col-md-9"> 
    <v-card elevation="3">
        <v-card-title class="text-h7" style="color:#666">
          Biometric Enrolment Result
        </v-card-title>
                  <v-divider style="margin-top:0px"></v-divider>
                  <div class="pa-4">
<v-alert type="error" class="text-h5">Could not enroll Applicant</v-alert>
    <p>{{ msg }}</p>
                        <v-btn color="success" elevation="5" @click="recapture">Recapture</v-btn><br>
                  </div>
    </v-card>
      </div>
    
  </div>
</template>

<script>
import router from '@/router/index'
export default {
  name: "BiometricError",

  data() {
    return {
      msg: "",
    };
  },

  mounted() {
    const err = this.$route.query.error;
    this.msg = err;
  },
  methods:{
recapture(){
router.push({
                                path: '/students/biometrics'
                            })}
  }
};
</script>
