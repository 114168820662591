<template>
<div class="home">
    <!-- <v-app-bar app color="white" dark prominent style="height:70px !important;" class="pt-4">
        <span style="color:#777; font-size:21px">Biometric Clearance</span>
    </v-app-bar> -->
    <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc">
            <span style="color:#777; font-size:18px;padding:10px">Student Biometric Capture</span>
        </div>
    </div>
    <div style="margin-top:135px;">

        <div v-if="trigeredCapture">
            <iframe :srcdoc="pageData" frameBorder="0" style="border: 0; width: 100%; height: 94vh; margin-top:50px">
                <p>Your browser does not support iframes.</p>
            </iframe>
        </div>
        <div v-if="!trigeredCapture">
            <v-row>
                <div style="margin:auto; padding:20px;margin-top:100px" class="col-md-5" v-if="!showBiometric">
                    <v-card elevation="3" height="300">
                        <v-card-title class="text-h7" style="color:#666">
                            Find Student
                        </v-card-title>
                        <v-divider style="margin-top:0px"></v-divider>

                        <v-col cols="9" style="margin:auto;padding-top:45px" pa-4>
                            <v-text-field outlined label="Student Identity" v-model="studentIdentity" dense hint="Use Student registration number as identification"></v-text-field>
                            <v-btn color="primary" @click="find_admited_student" elevation='2' class="col-md-12" :disabled="studentIdentity==''">Find</v-btn>
                        </v-col>
                    </v-card>
                </div>
            </v-row>
            <div style="margin:auto; padding:20px;margin-top:50px" class="col-md-9" v-if="showBiometric">
                <v-card elevation="3">
                    <v-card-title class="text-h7" style="color:#666">
                        Biometric Capture
                    </v-card-title>
                    <v-divider style="margin-top:0px"></v-divider>
                    <div class="ma-5" style="margin-bottom:10px !important">
                        <div class="row">
                            <div class="col-md-2">
                                <img :src="studentInfo.message.profileUrl" width="150px">
                            </div>
                            <div class="col-md-8">
                                STUDENT INFORMATION
                                <v-divider style="margin-top:0px"></v-divider>
                                <div class="row">

                                    <v-col cols="12" sm="4">
                                        <v-text-field v-model="studentInfo.message.surname" readonly label="Surname"></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="4">
                                        <v-text-field v-model="studentInfo.message.firstname" readonly label="Name"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4">
                                        <v-text-field v-model="studentInfo.message.othernames" readonly label="Name"></v-text-field>
                                    </v-col>
                                </div>
                                <div class="row">
                                    <v-col cols="12" sm="4">
                                        <v-text-field v-model="studentInfo.message.gender" readonly label="Gender"></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="4">
                                        <v-text-field v-model="studentInfo.message.DOB +'/'+ studentInfo.message.MOB+'/'+ studentInfo.message.YOB" readonly label="Date of Birth"></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="4">
                                        <v-text-field v-model="studentInfo.message.regNumber" readonly label="Registration No"></v-text-field>
                                    </v-col>
                                </div>
                                <!-- {{studentInfo}} -->
                                <!-- <v-text-field  outlined
             label="Student Identity"  v-model="studentIdentity"></v-text-field>
            <v-btn color="primary" @click="find_admited_student" elevation='2' class="col-md-12" :disabled="studentIdentity==''">Find</v-btn> -->
                                <!-- <v-btn color="success" elevation="5" @click="verifyStudent(studentInfo.message.regnumber)">Verify Applicant</v-btn><br> -->

                            </div>
                            <div class="col-md-2" style="margin-top:30px">
                                <div class="row" style="text-align:center">
                                    <v-col class="col-md-6" v-if="studentInfo.message.status ==4">
                                        <v-dialog v-model="dialog" max-width="600px">
                                            <template v-slot:activator="{ on, attrs }">
                                                <!-- <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        > -->
                                                <img src="../assets/finger.svg" width="100" style="cursor:pointer" v-bind="attrs" v-on="on"><br>
                                                Finger Biometric
                                                <!-- </v-btn> -->
                                            </template>
                                            <v-card>
                                                <v-card-title>
                                                    <span class="text-h5">Verification Confirmation</span>
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-container>
                                                        <v-row>
                                                            <v-col cols="12" sm="12" md="12">

                                                                I hereby Confirm that the person in this bio is the same as appeared in the Jamb Biometrics <br><br>
                                                                <v-btn @click="vrifyFinger">Confirm</v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-card-text>
                                            </v-card>

                                        </v-dialog>
                                    </v-col>
                                    <v-col class="col-md-6" @click="trigerBiometrics(studentIdentity)" v-if="studentInfo.message.profileUrl==''">
                                        <img src="../assets/face.png" width="100" style="cursor:pointer"><br>
                                        <strong style="cursor:pointer">Facial Biometric</strong></v-col>
                                </div>
                                <!-- <img :src="success" width="200px"> -->
                                <!-- <v-text-field  outlined
             label="Student Identity"  v-model="studentIdentity"></v-text-field>
            <v-btn color="primary" @click="find_admited_student" elevation='2' class="col-md-12" :disabled="studentIdentity==''">Find</v-btn> -->
                            </div>
                        </div>
                    </div>

                </v-card>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2';
// import {
//     getLoggedInUser,
//     getCookie,
//     getUrl,
//     hideActivityLoader
// } from '@Appmart/utility'

export default {
    name: "Home",
    data: () => ({
        studentInfo: '',
        trigeredCapture: false,
        studentIdentity: '',
        showBiometric: false,
        pageData: `<html><head></head><body><p>Loading Biometric Capture</p></body></html>`,

    }),
    mounted() {
        // remove modal on entry
        this.hideActivityLoader()
    },
    methods: {
         getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return false;
},
ShowActivityLoader() {    
  if (document.querySelector("#pleaseWaitDialog") == null) {
      var modalLoading = '<div class="modal" id="pleaseWaitDialog" data-backdrop="static" data-keyboard="false" role="dialog" style="margin-top:40vh">\
          <div class="modal-dialog" style="background-color:transparent;border:none">\
              <div class="modal-content" style="background-color:transparent; text-align:center;border:none">\
                  <div class="modal-body">\
                      <div class="spinner-border" role="status" style="color:#fff">\
  <span class="sr-only"></span>\
</div>\
<div style="color:#fff">Loading ...</div>\
                  </div>\
              </div>\
          </div>\
      </div>';
      $(document.body).append(modalLoading);
  }

  $("#pleaseWaitDialog").modal("show");
},
hideActivityLoader() {
  $("#pleaseWaitDialog").modal("hide");
},

parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
},
        async trigerBiometrics(x) {

            // faking data

            const data = {
                key: "3G021VDRE1SzCiHfpyQeaSi0F",
                UserID: x,
                ReturnUrl: `${window.location.origin}/#/students/biometricSuccess`,
                ErrorUrl: `${window.location.origin}/#/students/biometricError`,
            };

            const config = {
                method: "post",
                url: this.$appUrl+'startface_Capture',
                data: data,
            };

            await axios(config)
                .then((response) => {
                    const message = response.data.message;
                    const data = response.data.data;
                    console.log({
                        message
                    });
                    this.pageData = data;
                    this.trigeredCapture = true
                })
                .catch((error) => {
                    console.log({
                        error
                    });
                });
        },
        // async vrifyFinger() {
        //     const url = this.$appUrl+ 'veri_finger'
        //     const findAdmited = await axios({
        //         method: 'POST',
        //         url: url,
        //         data: {
        //             year: '2021-2022',
        //             entryData: this.studentIdentity
        //         }
        //     }).then(response => {
        //         const responses = response.data
        //         if (responses.code == '00') {
        //             Swal.fire({
        //                 icon: 'success',
        //                 title: 'Biometrics',
        //                 text: 'Student Verified',
        //             })
        //             setTimeout(() => {
        //                 this.$router.go()
        //             }, 500);

        //         } else {
        //             Swal.fire({
        //                 icon: 'error',
        //                 title: 'Biometrics',
        //                 text: 'Admitted Student Not Found',
        //             })
        //         }
        //         if (response.data.code != '00') {
        //             console.log(response.data);
        //         }

        //     })
        // },

        async find_admited_student() {
            this.showBiometric = false
            const url = this.$appUrl+'findtocapture'
            const findAdmited = await axios({
                    method: 'POST',
                    url: url,
                    data: {
                        regNumber: this.studentIdentity
                    }
                })
                .then(response => {
                    const responses = response.data
                    if (responses.code == '00') {
                            this.studentInfo = responses
                            this.showBiometric = true
                       
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Biometrics',
                            text: 'Admitted Student Not Found',
                        })
                    }
                    if (response.data.code != '00') {
                        console.log(response.data);
                    }

                    hideActivityLoader()

                })

                .catch(e => {
                    hideActivityLoader()
                    console.log(e);
                    alert('Something went wrong')
                })
        }
    }
};
</script>

<style scoped>
/* .home {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
} */

section {
    display: flex;
    width: 50%;
    justify-content: space-between;
}

a {
    text-decoration: none;
    display: inline-block;
}

.btn,
a {
    width: 45%;
    cursor: pointer;
    padding: 20px 20px;
    border: 1px solid gray;
    font-size: 1.2rem;
    font-family: "Courier New", Courier, monospace;
    font-weight: bold;
    color: #181717;
    background: #ffffff;
}
</style>
