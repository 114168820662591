import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';

import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify'
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import {getLoggedInUser,getCookie,getUrl,parseJwt,allFaculties} from '@Appmart/utility'

// import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
// import 'roboto-fontface/css/roboto/roboto-fontface.css'
// import '@mdi/font/css/materialdesignicons.css'
Vue.use(getLoggedInUser,getCookie,getUrl,parseJwt,allFaculties);
Vue.use(BootstrapVue);
Vue.config.productionTip = false;

///// URL API FOR THIS MICRO APP 
Vue.prototype.$appUrl = process.env.VUE_APP_STUDENTS_V1_API_ENDPOINT

/////// get the data of who is loged in
let name = getCookie("-X_Zka");
    if(!name){
  window.location.href =`${window.location.origin}/#/auth`;
    }
  const tokendecoded = parseJwt(name);
  console.log(tokendecoded);
  Vue.prototype.$logedIndataId = tokendecoded.staffid;
//staffId


const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App, {
        props: {
          // single-spa props are available on the "this" object. Forward them to your component as needed.
          // https://single-spa.js.org/docs/building-applications#lifecyle-props
          // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
          /*
          name: this.name,
          mountParcel: this.mountParcel,
          singleSpa: this.singleSpa,
          */
        },
      });
    },
    router,
    store,
    vuetify
  },
});
// new Vue({
//   router,
//   store,
//   vuetify,
//   render: h => h(App)
// }).$mount('#app')
export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
